<template>
	<div class="carrierWrap carrierWrapBundle" v-bind:class="{ 'expanded': (expanded === true), 'lowestPrice': (insquote.isLowest === true), 'hasError': (insquote.errored === true) }">
		<div class="productToggleWrap" v-if="showProductToggles === true">
			<div class="productToggle" id="productToggle-auto" :class="{ 'active': (productToggleStatus == 'Auto') }" v-on:click="toggleProduct('Auto')">
				<icon-helper :glyph="'auto'" :variant="iconvariant"></icon-helper>
			</div>
			<div class="productToggle" id="productToggle-home" :class="{ 'active': (productToggleStatus == 'Home') }" v-on:click="toggleProduct('Home')">
				<icon-helper :glyph="'home'" :variant="iconvariant"></icon-helper>
			</div>
		</div>
		<div class="carrierWrapInner" v-bind:class="{ 'withToggles': (showProductToggles === true) }">
			<div class="carrierBasics row">
				<img class="lpCorner lpCorner-desktop show-for-medium" :src="asset('lowestPrice-orange-wide.png')" alt="Lowest Price" v-if="(insquote.isLowest === true)">
				<img class="lpCorner lpCorner-mobile hide-for-medium" :src="asset('lowestPrice-orange.png')" alt="Lowest Price" v-if="(insquote.isLowest === true)">
				<div class="carrierLogo1" :class="{ 'noPointer': hasAnyTotal === false }" v-on:click="expand()">
					<img :src="insquote.carrier_logo_url" :alt="insquote.carrier_name">
				</div>
				
				<div class="carrierProductsWrap" v-show="hasAnyTotal === true">
					
					<!-- Regular AUTO and HOME -->
					<price-block :key="'priceblock_auto'" :blocktype="'Auto'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptAuto" :attempted="attemptedAutoQuote" :hastotal="hasAutoTotal" :parentexpanded="expanded" v-if="blocksView == 'REGULAR'" v-show="willAttemptAuto === true || (autoIsActive === true && willAttemptAuto === false)" :spinnerstopped="spinners_stopped"></price-block>
					<price-block :key="'priceblock_home'" :blocktype="'Home'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptHome" :attempted="attemptedHomeQuote" :hastotal="hasHomeTotal" :parentexpanded="expanded" v-if="blocksView == 'REGULAR'" v-show="willAttemptHome === true || (homeIsActive === true && willAttemptHome === false)" :spinnerstopped="spinners_stopped"></price-block>
					
					<!-- AUTO plus STATE MINIMUM -->
					<price-block :key="'priceblock_altAuto'" :blocktype="'altAuto'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptAltAuto" :attempted="attemptedAltAutoQuote" :hastotal="hasAltAutoTotal" :parentexpanded="expanded" :quotelabel="'CA State Minimum'" v-if="blocksView == 'AUTO_PLUS_MIN'" v-show="willAttemptAltAuto === true || (altAutoIsActive === true && willAttemptAltAuto === false)" :spinnerstopped="spinners_stopped"></price-block>
					<price-block :key="'priceblock_auto'" :blocktype="'Auto'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptAuto" :attempted="attemptedAutoQuote" :hastotal="hasAutoTotal" :parentexpanded="expanded" :quotelabel="'Enhanced Coverage'" v-if="blocksView == 'AUTO_PLUS_MIN'" v-show="willAttemptAuto === true || (autoIsActive === true && willAttemptAuto === false)" :spinnerstopped="spinners_stopped"></price-block>
					
					<!-- AUTO plus ENHANCED -->
					<price-block :key="'priceblock_auto'" :blocktype="'Auto'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptAuto" :attempted="attemptedAutoQuote" :hastotal="hasAutoTotal" :parentexpanded="expanded" :quotelabel="'CA State Minimum'" v-if="blocksView == 'AUTO_PLUS_ENHANCED'" v-show="willAttemptAuto === true || (autoIsActive === true && willAttemptAuto === false)" :spinnerstopped="spinners_stopped"></price-block>
					<price-block :key="'priceblock_altAuto'" :blocktype="'altAuto'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptAltAuto" :attempted="attemptedAltAutoQuote" :hastotal="hasAltAutoTotal" :parentexpanded="expanded" :quotelabel="'Enhanced Auto'" v-if="blocksView == 'AUTO_PLUS_ENHANCED'" v-show="willAttemptAltAuto === true || (altAutoIsActive === true && willAttemptAltAuto === false)" :spinnerstopped="spinners_stopped"></price-block>
					
					<!-- HOME ONLY -->
					<price-block :key="'priceblock_home'" :blocktype="'Home'" :insquote="insquote" :iconvariant="iconvariant" :willattempt="willAttemptHome" :attempted="attemptedHomeQuote" :hastotal="hasHomeTotal" :parentexpanded="expanded" v-if="blocksView == 'HOME_ONLY'" v-show="willAttemptHome === true || (homeIsActive === true && willAttemptHome === false)" :spinnerstopped="spinners_stopped"></price-block>
					
				</div>
	
				<div class="noPricesWrap" v-show="hasAnyTotal === false">

					<div class="noPriceBlock autoResultNoPrice" :class="'theme-' + theme" v-show="attemptedAutoQuote === true || (willAttemptAuto === true && spinners_stopped === true) || (autoIsActive === true && willAttemptAuto === false)">
						<div class="noPriceIcon"><icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'carIcon'"></icon-helper></div>
						<div class="quoteNoPrice">
							Please call us for&nbsp;details.<span class="doubleDagger">&#8225;</span>
						</div>
					</div>
					<div class="placeholder placeholderAuto priceBlock" v-show="spinners_stopped === false && willAttemptAuto === true && attemptedAutoQuote !== true">
						<div class="quoteTopRow">
							<icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'carIcon'"></icon-helper>
						</div>
						<div class="quoteRetrieving">
							<div class="lds-progress progblack"><div></div><div></div><div></div></div> Retrieving rates
						</div>
					</div>

					<div class="noPriceBlock homeResultNoPrice" :class="'theme-' + theme" v-show="attemptedHomeQuote === true || (willAttemptHome === true && spinners_stopped === true) || (homeIsActive === true && willAttemptHome === false)">
						<div class="noPriceIcon"><icon-helper :glyph="'home'" :variant="iconvariant" :classes="'houseIcon'"></icon-helper></div>
						<div class="quoteNoPrice">
							Please call us for&nbsp;details.<span class="doubleDagger">&#8225;</span>
						</div>
					</div>
					<div class="placeholder placeholderHome priceBlock" v-show="spinners_stopped === false && willAttemptHome === true && attemptedHomeQuote !== true">
						<div class="quoteTopRow">
							<icon-helper :glyph="'home'" :variant="iconvariant" :classes="'houseIcon'"></icon-helper>
						</div>
						<div class="quoteRetrieving">
							<div class="lds-progress progblack"><div></div><div></div><div></div></div> Retrieving rates
						</div>
					</div>
					
				</div>
			</div>
	
			<div class="carrierDetails">
				<img class="closeX" :src="closeXimage" alt="(X)" v-on:click="collapse()">
				<div class="coveragesWrap">
					<div class="coveragesBlock" v-if="hasAltAutoTotal === true && limit != 'State Minimum'">
						<h3>State Minimum Auto Coverage</h3>
						<div class="coveragesRow">
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.a }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.a)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.c }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.c)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.e }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.e)"></span>
								</p>
							</div>
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.b }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.b)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.d }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.d)"></span>
								</p>
							</div>
						</div>
					</div>

					<div class="coveragesBlock autoCoverages" v-if="hasAutoTotal">
						<h3>{{ autoCoverageHeadline }}</h3>
						<div class="coveragesRow">
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.a }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Auto.coverages.a)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.c }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Auto.coverages.c)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.e }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Auto.coverages.e)"></span>
								</p>
							</div>
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.b }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Auto.coverages.b)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.d }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Auto.coverages.d)"></span>
								</p>
							</div>
						</div>
					</div>
	
					<div class="coveragesBlock" v-if="hasAltAutoTotal === true && limit == 'State Minimum'">
						<h3>Enhanced Auto Coverage</h3>
						<div class="coveragesRow">
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.a }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.a)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.c }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.c)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.e }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.e)"></span>
								</p>
							</div>
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsAuto.b }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.b)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsAuto.d }}</strong><br>
									<span v-html="amount(insquote.productQuotes.altAuto.coverages.d)"></span>
								</p>
							</div>
						</div>
					</div>

					<div class="coveragesBlock homeCoverages" v-if="hasHomeTotal">
						<h3>Home Coverage</h3>
						<div class="coveragesRow">
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsHome.a }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.a)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsHome.c }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.c)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsHome.e }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.e)"></span>
								</p>
							</div>
							<div class="coverageGroup">
								<p>
									<strong>{{ coverageLabelsHome.b }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.b)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsHome.d }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.d)"></span>
								</p>
								<p>
									<strong>{{ coverageLabelsHome.f }}</strong><br>
									<span v-html="amount(insquote.productQuotes.Home.coverages.f)"></span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	props: {
		insquote: {
			type: Object,
			required: true
		},
		activeprods: {
			type: Array, 
			required: true
		}, 
		usemin: {
			type: Boolean, 
			default: false
		}, 
		limit: {
			type: String, 
			required: false
		}, 
		memberlabel: {
			type: String, 
			default: 'Member Pricing'
		}, 
		iconvariant: {
			type: String,
			required: true
		}, 
		theme: {
			type: String, 
			default: 'default'
		}
	},
	data: function() {
		return {
			assetRoot: 'cdn/', /* TODO: Change to S3 later */
			expanded: false, 
			productToggleStatusManual: 'Auto', 
			productToggleWasClicked: false, 
			spinners_stopped: false
		}
	},
	computed: {
		attemptedAutoQuote: function() {
			return (window.is.existy(this.insquote.productQuotes) && window.is.existy(this.insquote.productQuotes.Auto));
		},
		attemptedAltAutoQuote: function() {
			return (window.is.existy(this.insquote.productQuotes) && window.is.existy(this.insquote.productQuotes.altAuto));
		}, 
		attemptedHomeQuote: function() {
			return (window.is.existy(this.insquote.productQuotes) && window.is.existy(this.insquote.productQuotes.Home));
		},
		willAttemptAuto: function() {
			return (this.insquote.willAttemptProducts.indexOf('Auto') > -1);
		},
		willAttemptAltAuto: function() {
			return (this.insquote.willAttemptProducts.indexOf('altAuto') > -1);
		},
		willAttemptHome: function() {
			return (this.insquote.willAttemptProducts.indexOf('Home') > -1);
		},
		autoIsActive: function() {
			return (this.activeprods.indexOf('Auto') > -1);
		},
		altAutoIsActive: function() {
			return (this.activeprods.indexOf('altAuto') > -1);
		},
		homeIsActive: function() {
			return (this.activeprods.indexOf('Home') > -1);
		},
		hasAutoTotal: function() {
			if (this.insquote.hasOwnProperty('productQuotes') === false) { return false; }
			if (this.insquote.productQuotes.hasOwnProperty('Auto') === false) { return false; }
			if (this.insquote.productQuotes.Auto.hasOwnProperty('coverages') === false) { return false; }
			return (isNaN(this.insquote.productQuotes.Auto.coverages.total) === false && this.insquote.productQuotes.Auto.coverages.total != null && this.insquote.productQuotes.Auto.coverages.total > 0);
		},
		hasAltAutoTotal: function() {
			if (this.insquote.hasOwnProperty('productQuotes') === false) { return false; }
			if (this.insquote.productQuotes.hasOwnProperty('altAuto') === false) { return false; }
			if (this.insquote.productQuotes.altAuto.hasOwnProperty('coverages') === false) { return false; }
			return (isNaN(this.insquote.productQuotes.altAuto.coverages.total) === false && this.insquote.productQuotes.altAuto.coverages.total != null && this.insquote.productQuotes.altAuto.coverages.total > 0);
		},
		hasHomeTotal: function() {
			if (this.insquote.hasOwnProperty('productQuotes') === false) { return false; }
			if (this.insquote.productQuotes.hasOwnProperty('Home') === false) { return false; }
			if (this.insquote.productQuotes.Home.hasOwnProperty('coverages') === false) { return false; }
			return (isNaN(this.insquote.productQuotes.Home.coverages.total) === false && this.insquote.productQuotes.Home.coverages.total != null && this.insquote.productQuotes.Home.coverages.total > 0);
		},
		hasAnyTotal: function() {
			return (this.hasAutoTotal === true || this.hasAltAutoTotal === true || this.hasHomeTotal === true);
		},
		hasTel: function() {
			return ((typeof this.insquote.carrier_phone) == 'string' && this.insquote.carrier_phone.length > 0);
		},
		tel: function() {
			if (this.hasTel === false) { return ''; }
			let out = this.insquote.carrier_phone;
			if (out.substring(0, 1) == '+') { out = out.substring(1); } // Strip the + symbol from the phone number.
			if (out.length == 11 && out.substring(0, 1) == '1') {
				// 10-digit phone number. Add dots.
				return out.substring(1, 4) + '.' + out.substring(4, 7) + '.' + out.substring(7);
			}
			// Unknown format. Return the value raw.
			return out;
		},
		isAutoQuote: function() {
			return (this.insquote.quote_type == 'auto');
		},
		coverageLabelsAuto: function() {
			return {
				a: 'Bodily injury',
				b: 'Medical payments',
				c: 'Property damage',
				d: 'Underinsured motorist',
				e: 'Uninsured motorist',
				f: ''
			};
		},
		coverageLabelsHome: function() {
			return {
				a: 'Repair/rebuild your home',
				b: 'Repair/rebuild other structures',
				c: 'Personal possessions',
				d: 'Temporary living expenses',
				e: 'Personal liability',
				f: 'Medical payments to others'
			};
		}, 
		showProductToggles: function() {
			return (this.usemin === true && this.willAttemptHome === true && this.willAttemptAuto === true && this.hasAnyTotal === true);
		}, 
		productToggleStatus: function() {
			if (this.showProductToggles === true && this.hasHomeTotal === true && this.hasAutoTotal === false && this.hasAltAutoTotal === false && this.productToggleWasClicked === false) {
				// If home is the only rated product for a carrier, flip to that tab by default
				return 'Home';
			}
			return this.productToggleStatusManual;
		}, 
		blocksView: function() {
			if (this.showProductToggles === false) { 
				if (this.usemin === true && this.willAttemptAuto === true && this.willAttemptAltAuto === true) {
					if (this.limit != 'State Minimum') { return 'AUTO_PLUS_MIN'; }
					return 'AUTO_PLUS_ENHANCED';
				}
				return 'REGULAR'; 
			}
			if (this.showProductToggles === true && this.productToggleStatus == 'Auto' && this.limit != 'State Minimum') { return 'AUTO_PLUS_MIN'; }
			if (this.showProductToggles === true && this.productToggleStatus == 'Auto' && this.limit == 'State Minimum') { return 'AUTO_PLUS_ENHANCED'; }
			if (this.showProductToggles === true && this.productToggleStatus == 'Home') { return 'HOME_ONLY'; }
			return 'ERR'; // Should never get here.
		}, 
		autoCoverageHeadline: function() {
			if (this.showProductToggles === false) { return 'Auto Coverage'; }
			if (this.limit != 'State Minimum') { return 'Enhanced Auto Coverage'; }
			return 'State Minimum Auto Coverage';
		}, 
		closeXimage: function() {
			let out = this.asset('x-circle.png');
			if (this.theme == 'seiu') {
				out = this.asset('x-circle-e01719.png');
			}
			return out;
		}
	},
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		},
		amount: function(n) {
			if (n == null) { return 'Call for Details'; }
			const lookups = {
				'50000/100000': `$50,000/$100,000`,
				'100000/300000': `$100,000/$300,000`,
				'250000/500000': `$250,000/$500,000`
			};
			if (Object.keys(lookups).indexOf(n) > -1) {
				return lookups[n];
			}
			return '$' + n.toLocaleString();
		},
		startScheduleCall: function() {
			window.Rates.showScheduleCallForm();
		},
		toggleProduct: function(prod) {
			this.productToggleStatusManual = prod;
			this.productToggleWasClicked = true;
		}, 
		numFormat: function(n) {
			if (isNaN(n) === true || n == null) { return ''; }
			return '' + n.toLocaleString();
		},
		expand: function() {
			if (this.hasAnyTotal === true) {
				this.expanded = true;
			}
		},
		collapse: function() {
			this.expanded = false;
		}, 
		stopAllSpinners: function() {
			this.spinners_stopped = true;
		}
	}, 
	mounted: function() {
		window.addEventListener('forceCompletedState', this.stopAllSpinners);
	}
}
</script>